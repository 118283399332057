<template>
  <div>
    <b-list-group v-if="dataItem.lines.length > 0">
      <b-list-group-item
        v-for="(line,key) in dataItem.lines"
        :key="line.id"
      >
        <b-row class="match-height d-flex align-items-center">
          <b-col cols="auto">
            <div class="font-weight-light font-medium-2">
              {{ key + 1 }}.
            </div>
          </b-col>
          <b-col>
            <div class="text-primary font-weight-bold">
              Başlat
            </div>
            {{ moment(line.sdate).format('DD.MM.YYYY HH:mm:ss') }}
          </b-col>
          <b-col>
            <div class="text-primary font-weight-bold">
              Duraklat
            </div>
            {{ line.edate? moment(line.edate).format('DD.MM.YYYY HH:mm:ss') : 'İşlem devam ediyor...' }}
          </b-col>
          <b-col>
            <div class="text-primary font-weight-bold">
              Süre
            </div>
            {{ line.edate? moment.utc(moment.duration(line.seconds * 1000).asMilliseconds()).format('HH:mm:ss') : 'İşlem devam ediyor...' }}
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-alert
      v-else
      show
      variant="warning"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        İş Emri için henüz hiç işlem başlatılmamış.
      </div>
    </b-alert>
  </div>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'WorkOrder',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
